import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import BackgroundImg from "gatsby-background-image";
import BlockContent from "@sanity/block-content-to-react";

import Layout from "../layouts/default";
import SEO from "../utils/seo";
import PostCardGrid from "../components/PostCardGrid";
import BlockContentImage from "../components/BlockContentImage";

import * as styles from "./index.module.css";
import HeroConnectionText from "../images/home-hero-connection.svg";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />

      <section>
        <BackgroundImg
          fluid={data.pageHeroImage.image.fluid}
          className={styles.hero}
          id="hero"
        >
          <div className={styles.textSmall}>Exploring</div>
          <div className={styles.textSmall}>the</div>
          <img
            className={styles.textLarge}
            src={HeroConnectionText}
            alt="MIND/BODY"
          />
          <div className={styles.textSmall}>connection</div>
        </BackgroundImg>
      </section>
      <div className="md:w-3/4 w-full mx-auto px-6">
        <section className={styles.pageSection}>
          <h1 className="text-heading">Mission</h1>
          <div className="text-gray-700 style-normal">
            <BlockContent
              blocks={data.settings.mission || []}
              serializers={{
                types: {
                  captionedImage: BlockContentImage,
                },
              }}
              projectId={process.env.GATSBY_SANITY_ID}
              dataset={process.env.GATSBY_SANITY_DATASET}
            />
          </div>
        </section>
        <section className={styles.pageSection}>
          <h1 className="text-heading">Support</h1>
          <div className="flex flex-wrap items-center">
            {data.settings.organizations.map((org, i) => (
              <div key={i} className="lg:w-1/4 sm:w-1/3 w-1/2 my-2 ">
                <Img
                  className="mb-2 mx-2"
                  fluid={org.image.asset.fluid}
                  alt={org.name}
                />
              </div>
            ))}
          </div>
        </section>
        <section className={styles.pageSection}>
          <h1 className="text-heading">Recent Posts</h1>
          <PostCardGrid posts={data.posts.nodes} />
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageQuery {
    settings: sanitySettings {
      organizations {
        name
        description
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      mission: _rawMission
    }
    pageHeroImage: file(relativePath: { eq: "home-hero.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    posts: allSanityPost(limit: 3) {
      ...BlogPosts
    }
  }
`;

export default IndexPage;
